<template>
  <v-theme-provider dark>
    <base-section
      id="divider"
      class="jean"
      space="20"
    >
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionContactUs',
  }
</script>
